<template>
  <div class="container-home">
    <div class="container-image">
      <div class="logo-image">
        <img class="img-fluid " src="/logo-single.png" alt="SofTorres Innovacion Soluciones Tecnologicas Mexico Sistemas a la medida">
        <p class="logo-text text-center display-1"><strong>SofTorres</strong></p>
        <p class="logo-text text-center display-5">Innovación y Soluciones en Tecnología</p>
      </div>
      <div class="separator-line"></div>
<!--      <hr style="background-color: white;color:white;">-->
      <p class="text-center text-contact-info pb-5">Ventas y Contacto <br><a href="mailto:contacto@softorres.com">contacto@softorres.com</a></p>
    </div>
  </div>
</template>

<script>
export default {
  name: "IndexHome"
}
</script>

<style scoped>
a{
  color: white !important;
}
.container-home {
}
.separator-line {
  display: block;
  padding: 0.1px 0em;
  margin: 2em 8em;
  background-color: #f8d7da;
}
.container-image {
  display: block;
  width: 100%;
  margin: 0 auto;
  padding: 0em 0;
}
.container-image img {
  margin: 5em auto;
  display: block;
}

.container-image .logo-text {
  color: #4f4347;
}

.container-image .text-contact-info {
  color: #ff0018;
}

.container-image .text-contact-info a {
  color: #555 !important;
  font-weight: bold;
}
</style>